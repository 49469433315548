html,
body,
#root {
  height: 100%;
  margin: 0;
}

html {
  box-sizing: border-box;
  /*font-family: $typo-family;*/
  /*font-weight: $typo-weight;*/
  font-size: 10px;
  /*color: $color-black;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.form-grid-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  column-gap: 1.6rem;

  .ant-col-0 {
    display: unset;
  }
}

.page-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.page-layout__body {
  flex: 1;
  overflow: auto;
}
