.app {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.top {
  flex: 0;
  border-bottom: solid 1px var(--adm-color-border);
}

.body {
  flex: 1;
  background-color: #f9fbfc;
  overflow: auto;
}

.bottom {
  flex: 0;
  border-top: solid 1px var(--adm-color-border);
}

.layout {
  background-color: #78909b;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.layoutInner {
  height: 100%;
  width: 100%;
  max-width: 820px;
}
