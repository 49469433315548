/* Общие стили для списков и элементов */
.flight-list,
.passenger-list {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.flight-item,
.passenger-card {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;
}

.flight-item:hover,
.passenger-card:hover {
  background-color: #f0f0f0;
}

/* Стили для различных состояний элементов */
.flight-item.past,
.passenger-card.past {
  background-color: #e0e0e0;
  color: #b0b0b0;
  cursor: default;
}

.flight-item.today,
.passenger-card.today {
  border-color: #004085;
  background-color: #cce5ff;
  color: #004085;
}

.flight-item.tomorrow,
.passenger-card.tomorrow {
  border-color: #155724;
  background-color: #d4edda;
  color: #155724;
}

/* Общие стили для заголовков и параграфов */
.flight-details h3,
.passenger-card h3,
.tel-link {
  margin: 0;
  color: #333;
}

.flight-details p,
.passenger-card p {
  margin: 5px 0 0;
  font-size: 0.9em;
  color: #666;
}

/* Стилизация телефонных ссылок */
.tel-link {
  color: #0056b3;
  text-decoration: none;
}

.tel-link:hover {
  text-decoration: underline;
}
